import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useFxgtStore = defineStore('fxgt', {
  actions: {
		index: ({ filter, page = 1 }) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/fxgts?page=${page}${ filter ? '&filter=' + filter : '' }`),
		show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/fxgts/${id}`),
    store: (data) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/fxgts`, data),
    destroy: (id) => axios.delete(`${VUE_APP_ENDPOINT_URL}/api/admin/fxgts/${id}`),
  }
})
